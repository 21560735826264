<template>
    <section id="offerings" class="offerings">
      <h2>{{ $t('whatWeOffer') }}</h2>
      <div class="tabs">
        <button
          v-for="tab in tabs"
          :key="tab.id"
          :class="{ active: activeTab === tab.id }"
          @click="activeTab = tab.id"
        >
          {{ $t(tab.label) }}
        </button>
      </div>
      <div class="offerings-list">
        <div
          v-for="offering in filteredOfferings"
          :key="offering.id"
          class="offering-item"
        >
          <h3>{{ offering.title }}</h3>
          <p>{{ offering.description }}</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OfferingsSection',
    data() {
      return {
        activeTab: 'investors',
        tabs: [
          { id: 'investors', label: 'tabs.investors' },
          { id: 'companies', label: 'tabs.companies' },
        ],
        offerings: [], // Изначально пустой
      };
    },
    computed: {
      filteredOfferings() {
        return this.offerings.filter(
          (offering) => offering.category === this.activeTab
        );
      },
    },
    watch: {
      // Отслеживаем изменения текущей локали
      '$i18n.locale': {
        immediate: true,
        handler() {
          this.loadOfferings(); // Перезагружаем данные при смене языка
        },
      },
    },
    methods: {
      loadOfferings() {
        this.offerings = [
          // Для инвесторов
          {
            id: 1,
            category: 'investors',
            title: this.$t('offerings.tokenIndex.title'),
            description: this.$t('offerings.tokenIndex.description'),
          },
          {
            id: 2,
            category: 'investors',
            title: this.$t('offerings.ownershipTokens.title'),
            description: this.$t('offerings.ownershipTokens.description'),
          },
          {
            id: 3,
            category: 'investors',
            title: this.$t('offerings.kycVerification.title'),
            description: this.$t('offerings.kycVerification.description'),
          },
          {
            id: 4,
            category: 'investors',
            title: this.$t('offerings.legalSupport.title'),
            description: this.$t('offerings.legalSupport.description'),
          },
          // Для компаний
          {
            id: 5,
            category: 'companies',
            title: this.$t('offerings.assetEvaluation.title'),
            description: this.$t('offerings.assetEvaluation.description'),
          },
          {
            id: 6,
            category: 'companies',
            title: this.$t('offerings.projectTracks.title'),
            description: this.$t('offerings.projectTracks.description'),
          },
          {
            id: 7,
            category: 'companies',
            title: this.$t('offerings.globalAccess.title'),
            description: this.$t('offerings.globalAccess.description'),
          },
        ];
      },
    },
  };
  </script>
  
  <style scoped>
  .offerings {
    padding: 4rem 2rem;
    display: flex; /* Flexbox для центрирования контента */
  flex-direction: column;
    text-align: center;
    background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(230, 245, 255, 1));
    height: 100vh;
    justify-content: center; 
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  
  .tabs button {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border: none;
    background: #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .tabs button.active {
    background: #0078d7;
    color: white;
  }
  
  .tabs button:hover {
    background: #005bb5;
    color: white;
  }
  
  .offerings-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
  }
  
  .offering-item {
    flex: 1 1 calc(40% - 1rem);
    padding: 2rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .offering-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .offering-item h3 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: #005bb5;
  }
  
  .offering-item p {
    font-size: 1rem;
    color: #444;
  }
  </style>