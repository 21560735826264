<!-- HomePage.vue -->
<template>
    <main>
      <HeroSection />
      <AdvantagesSection />
      <OfferingsSection />
      <HowItWorksSection />
      <ForInvestorsSection />
      <ForCompaniesSection />
      <GetStartedSection />
      <ContactUsSection />
    </main>
  </template>
  
  <script>
  import HeroSection from '@/components/HeroSection.vue';
  import AdvantagesSection from '@/components/AdvantagesSection.vue';
  import OfferingsSection from '@/components/OfferingsSection.vue';
  import HowItWorksSection from '@/components/HowItWorksSection.vue';
  import GetStartedSection from '@/components/GetStartedSection.vue';
  import ContactUsSection from '@/components/ContactUsSection.vue';
  
  export default {
    name: 'HomePage',
    components: {
      HeroSection,
      AdvantagesSection,
      OfferingsSection,
      HowItWorksSection,
      GetStartedSection,
      ContactUsSection,
    },
  };
</script>
<style scoped>
    main {
    /* Ensure main takes the full height needed */
        width: 100%;
    }
</style>