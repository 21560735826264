<!-- HowItWorks.vue -->
<template>
    <section class="how-it-works" id="how-it-works">
      <h2>{{ $t('howItWorks.title') }}</h2>
      <ol>
        <li v-for="(step, index) in steps" :key="index">
          <h3>{{ step.title }}</h3>
          <p>{{ step.description }}</p>
        </li>
      </ol>
    </section>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'HowItWorksSection',
    setup() {
      const { t } = useI18n();
  
      const steps = computed(() => [
        {
          title: t('howItWorks.steps.registration.title'),
          description: t('howItWorks.steps.registration.description'),
        },
        {
          title: t('howItWorks.steps.chooseInvestments.title'),
          description: t('howItWorks.steps.chooseInvestments.description'),
        },
        // ... other steps
      ]);
  
      return {
        steps,
      };
    },
  };
  </script>
  
  <style scoped>
.how-it-works {
  height: 100vh; /* Полная высота экрана */
  background: linear-gradient(135deg, #202040, #2a5bda); /* Темно-синий градиент */
  color: #ffffff; /* Белый текст для контраста */
  display: flex; /* Flexbox для центрирования контента */
  flex-direction: column;
  justify-content: center; /* Центрирование по вертикали */
  align-items: center; /* Центрирование по горизонтали */
  text-align: center;
  padding: 4rem 2rem;
}

.how-it-works h2 {
  font-size: 2.5rem; /* Размер заголовка */
  margin-bottom: 2.5rem;
  text-transform: uppercase; /* Верхний регистр */
  letter-spacing: 2px; /* Межбуквенный интервал */
  color: #ffdd44; /* Золотистый акцентный цвет */
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Тень для заголовка */
}

.how-it-works ol {
  list-style: none; /* Убираем маркеры списка */
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem; /* Пространство между элементами */
  max-width: 1200px; /* Ограничиваем ширину списка */
}

.how-it-works li {
  flex: 1 1 calc(30% - 1rem); /* Адаптивная ширина карточек */
  max-width: 300px;
  background: rgba(0, 0, 0, 0.6); /* Полупрозрачный темный фон */
  border-radius: 10px; /* Скругленные углы */
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Тень для карточек */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Анимация при наведении */
}

.how-it-works li:hover {
  transform: translateY(-8px); /* Поднимаем карточку */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Увеличиваем тень */
}

.how-it-works h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffd700; /* Золотой цвет для заголовков */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); /* Легкая тень */
}

.how-it-works p {
  font-size: 1rem;
  line-height: 1.6;
  color: #e0e0e0; /* Светло-серый текст */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); /* Легкая тень для читаемости */
}
</style>