<!-- Advantages.vue -->
<template>
    <section id="advantages" class="advantages">
      <h2>{{ $t('ourAdvantages') }}</h2>
      <div class="advantages-list">
        <div class="advantage-item" v-for="advantage in advantages" :key="advantage.id">
          <img :src="advantage.icon" :alt="advantage.title" />
          <h3>{{ advantage.title }}</h3>
          <p>{{ advantage.description }}</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'AdvantagesSection',
    setup() {
      const { t } = useI18n();
  
      const advantages = computed(() => [
      {
        id: 1,
        icon: '@/assets/national-identity.png',
        title: t('advantages.nationalIdentity.title'),
        description: t('advantages.nationalIdentity.description'),
      },
      {
        id: 2,
        icon: '@/assets/reliability.png',
        title: t('advantages.reliability.title'),
        description: t('advantages.reliability.description'),
      },
      {
        id: 3,
        icon: '@/assets/control.png',
        title: t('advantages.control.title'),
        description: t('advantages.control.description'),
      },
      {
        id: 4,
        icon: '@/assets/digitalization.png',
        title: t('advantages.digitalization.title'),
        description: t('advantages.digitalization.description'),
      },
      {
        id: 5,
        icon: '@/assets/ai-assistant.png',
        title: t('advantages.aiAssistant.title'),
        description: t('advantages.aiAssistant.description'),
      },
    ]);

  
      return {
        advantages,
      };
    },
  };
  </script>
  
  <style scoped>
  .advantages {
      height: 100vh; /* Полная высота */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(135deg, rgba(32, 32, 64, 0.9), rgba(64, 128, 192, 0.8)); /* Градиентный фон */
      color: #ffffff; /* Контрастный цвет текста */
      text-align: center;
      padding: 2rem;
  }
  
  .advantages h2 {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
  }
  
  .advantages-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
  }
  
  .advantage-item {
      flex: 1 1 calc(33.333% - 2rem); /* Адаптивный размер */
      max-width: 300px;
      background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
      border-radius: 10px;
      padding: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .advantage-item:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
  
  .advantage-item img {
      width: 100px;
      height: 100px;
      margin-bottom: 1rem;
  }
  
  .advantage-item h3 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      color: #ffcc00; /* Золотой цвет для заголовков */
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .advantage-item p {
      font-size: 1rem;
      color: #f0f0f0;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  </style>