// main.js
import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n/i18n';
import router from './router'; // Make sure this path is correct

const app = createApp(App);

app.use(i18n);
app.use(router); // Ensure the router is used here

app.mount('#app');