<template>
    <section id="get-started" class="get-started">
      <h2>{{ $t('getStartedNow.title') }}</h2>
      <p>{{ $t('getStartedNow.description') }}</p>
      <button @click="signUp">{{ $t('getStartedNow.button') }}</button>
    </section>
  </template>
  
  <script>
  export default {
    name: 'GetStartedSection',
    setup() {
      const signUp = () => {
        // Handle sign-up action
        window.location.href = '/signup';
      };
  
      return {
        signUp,
      };
    },
  };
  </script>
  
  <style scoped>
  .get-started {
      height: 100vh; /* Полная высота экрана */
      background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(230, 245, 255, 1)); /* Светлый градиент */
      padding: 4rem 2rem; /* Пространство вокруг секции */
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Центрирование контента */
      align-items: center; /* Центрирование по горизонтали */
      color: #333333; /* Темный текст */
      font-family: 'Poppins', sans-serif; /* Элегантный шрифт */
  }
  
  .get-started h2 {
      font-size: 3.5rem; /* Увеличенный размер заголовка */
      margin-bottom: 1.5rem;
      text-transform: uppercase; /* Верхний регистр */
      letter-spacing: 2px; /* Расстояние между буквами */
      color: #005bb5; /* Темно-синий акцентный цвет */
      text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Более выразительная тень */
      font-weight: 700; /* Жирный текст */
  }
  
  .get-started p {
      font-size: 1.5rem; /* Увеличенный размер текста */
      margin-bottom: 2.5rem;
      line-height: 2; /* Просторный межстрочный интервал */
      color: #555555; /* Темно-серый текст */
      max-width: 800px; /* Ограничение ширины описания */
      font-weight: 300; /* Легкий шрифт */
  }
  
  .get-started button {
      background-color: #4a90e2; /* Голубой акцентный фон */
      color: #ffffff; /* Белый текст */
      padding: 1rem 2rem; /* Увеличенные отступы */
      font-size: 1.5rem; /* Увеличенный размер текста кнопки */
      border: none; /* Убираем рамку */
      border-radius: 12px; /* Скругленные углы */
      cursor: pointer;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Легкая тень */
      transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Добавление анимации */
      font-family: 'Poppins', sans-serif; /* Шрифт для кнопки */
      font-weight: 600; /* Полужирный текст */
  }
  
  .get-started button:hover {
      background-color: #005bb5; /* Темно-синий при наведении */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Увеличенная тень при наведении */
      transform: scale(1.05); /* Легкое увеличение при наведении */
  }
  </style>