<template>
  <div id="app">
    <Header @changeLanguage="changeLanguage" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Header from './components/HeaderSection.vue';
import Footer from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  setup() {
    const { locale } = useI18n();

    const changeLanguage = (lang) => {
      if (!lang) {
        console.error('Received empty language value'); // Проверка, что значение не пустое
        return;
      }
      console.log(`Changing language to: ${lang}`); // Лог для отладки
      locale.value = lang; // Устанавливаем новый язык
    };

    return {
      changeLanguage,
    };
  },
};
</script>

<style>
/* Глобальные стили */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
</style>