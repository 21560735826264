<template>
    <section id="contact" class="contact-us">
      <h2>{{ $t('contactUs.title') }}</h2>
      <div class="contact-details">
        <p><strong>{{ $t('contactUs.email') }}:</strong> info@tryzub-tokenization.com</p>
        <p><strong>{{ $t('contactUs.phone') }}:</strong> +38 (096) 945-29-67</p>
        <p><strong>{{ $t('contactUs.address') }}:</strong> {{ $t('contactUs.addressDetails') }}</p>
      </div>
      <form @submit.prevent="sendMail">
        <label>
          {{ $t('contactUs.form.name') }}
          <input type="text" v-model="form.name" required />
        </label>
        <label>
          {{ $t('contactUs.form.email') }}
          <input type="email" v-model="form.email" required />
        </label>
        <label>
          {{ $t('contactUs.form.message') }}
          <textarea v-model="form.message" required></textarea>
        </label>
        <button type="submit">{{ $t('contactUs.form.submit') }}</button>
      </form>
    </section>
  </template>
  
  <script>
  import {Email} from "@/3d-party/smtp.js"
  export default {
    name: 'ContactUsSection',
    data() {
      return {
        form: {
          name: '',
          email: '',
          message: '',
        },
      };
    },
    methods: {
      sendMail() {
        if (Email && typeof Email.send === 'function') {
          // Sending email using SMTP.js
          Email.send({
            SecureToken: '441a5cfc-eda2-49d2-8ebb-7447723dedb1', // Token for sending
            To: 'info@tryzub-tokenization.com', // Recipient
            From: 'info@tryzub-tokenization.com', // Sender's email
            Subject: 'Contact by form',
            Body: `
              <h2>Contact Form Submission</h2>
              <p><strong>Name:</strong> ${this.form.name}</p>
              <p><strong>Email:</strong> ${this.form.email}</p>
              <p><strong>Message:</strong></p>
              <p>${this.form.message}</p>
            `,
          })
            .then(() => {
              alert('Message sent successfully!');
              this.form = { name: '', email: '', message: '' }; // Clear the form
            })
            .catch((error) => {
              console.error('Failed to send email:', error);
              alert('Failed to send the message. Please try again.');
            });
        } else {
          console.error('Email object is not available or send method is missing.');
          alert('Email service is not available. Please try again later.');
        }
      },
    },
  };
  </script>
  <style scoped>
  .contact-us {
      height: 100vh; /* Полная высота экрана */
      display: flex; /* Центрирование контента */
      flex-direction: column;
      justify-content: center; /* Вертикальное центрирование */
      align-items: center; /* Горизонтальное центрирование */
      background: linear-gradient(135deg, #202040, #2a5bda); /* Темно-синий градиентный фон */
      color: #ffffff; /* Белый текст для контраста */
      text-align: center;
      margin: 0;
      padding: 4rem 2rem; /* Пространство вокруг */
  }
  
  .contact-us h2 {
      font-size: 3rem; /* Заголовок секции */
      margin-bottom: 2.5rem;
      text-transform: uppercase; /* Верхний регистр */
      letter-spacing: 2px; /* Межбуквенный интервал */
      color: #ffdd44; /* Золотистый акцентный цвет */
      text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Легкая тень */
  }
  
  .contact-details {
      font-size: 1.2rem;
      margin-bottom: 2rem;
  }
  
  .contact-details p {
      margin: 0.5rem 0;
      color: #e0e0e0; /* Светло-серый текст */
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); /* Легкая тень для читаемости */
  }
  
  form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
      max-width: 500px;
  }
  
  form label {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      color: #dddddd;
  }
  
  form input,
  form textarea {
      padding: 1rem;
      font-size: 1rem;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
      border: 1px solid #555555;
      border-radius: 10px; /* Скругленные углы */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Легкая тень */
  }
  
  form input:focus,
  form textarea:focus {
      outline: none;
      box-shadow: 0 0 6px rgba(255, 221, 68, 0.8); /* Золотой фокус */
  }
  
  button {
      background-color: #ffdd44; /* Золотой цвет кнопки */
      color: #333333; /* Темный текст */
      border: none;
      padding: 1rem 2rem;
      font-size: 1.2rem;
      border-radius: 10px; /* Скругленные углы */
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Легкая тень */
  }
  
  button:hover {
      background-color: #ffd700; /* Более яркий золотой цвет */
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Увеличенная тень */
  }
  </style>