<template>
    <footer class="footer">
      <div class="footer-icons">
        <a href="#" target="_blank">
          <img src="@/assets/facebook.svg" alt="Facebook" />
        </a>
        <a href="#" target="_blank">
          <img src="@/assets/x.svg" alt="X" />
        </a>
        <!-- Add other social media links -->
      </div>
      <p>&copy; 2024 Tryzub Tokenization Platform</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterSection',
  };
  </script>
  
  <style scoped>
  /* Футер */
  .footer {
    position: relative; /* Положение относительно родителя */
    background: linear-gradient(135deg, #202040, #2a5bda); /* Темно-синий градиент */
    height: 200px; /* Высота футера */
    width: 100%; /* Полная ширина */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1; /* Устанавливаем футер поверх контента */
    padding: 1rem 0;
    color: #ffffff; /* Белый текст */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.5); /* Легкая тень сверху */
  }
  
  /* Иконки социальных сетей */
  .footer-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  .footer-icons img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .footer-icons img:last-child {
    margin-right: 0;
  }
  
  /* Текст футера */
  .footer p {
    font-size: 0.9rem;
    color: #aaaaaa;
    margin: 0;
  }
  
  /* Предыдущая секция */
  .previous-section {
    height: calc(100vh + 200px); /* Высота экрана плюс высота футера */
    position: relative;
    margin-top: -200px; /* Сдвиг вверх на высоту футера */
    z-index: 0; /* Разрешаем предыдущей секции заезжать под футер */
    background: linear-gradient(135deg, #ffffff, #f0f0f0); /* Пример фона */
  }
  </style>