<!-- HeroSection.vue -->
<template>
    <section id="home" class="hero-section">
      <h1>{{ $t('welcome') }}</h1>
      <p>{{ $t('tagline') }}</p>
      <button @click="getStarted">{{ $t('getStarted') }}</button>
    </section>
  </template>
  
  <script>
  export default {
    name: 'HeroSection',
    setup() {
      const getStarted = () => {
        document.getElementById('get-started').scrollIntoView({ behavior: 'smooth' });
      };
  
      return {
        getStarted,
      };
    },
  };
  </script>
  
  <style scoped>
.hero-section {
    height: 100vh; /* Повна висота екрану */
    display: flex; /* Центруємо контент */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('@/assets/hero-background.jpg'); /* Новий фон */
    background-size: cover; /* Покриває весь блок */
    background-position: center;
    background-attachment: fixed; /* Ефект паралаксу */
    margin: 0;
    padding: 0;
    color: #ffffff; /* Контрастний текст */
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7); /* Тінь для тексту */
}

.hero-section h1 {
    font-size: 3rem; /* Великий заголовок */
    font-weight: bold;
    margin-bottom: 1rem;
}

.hero-section p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.hero-section button {
    background-color: rgba(0, 0, 0, 0.6); /* Напівпрозорий чорний фон */
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.hero-section button:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Світліший фон при наведенні */
    color: #000000;
}
</style>