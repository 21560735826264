<template>
    <header class="navbar">
      <nav>
        <div class="logo">
          <router-link to="/">
            <img class="img" src="@/assets/logo.png" alt="Tryzub Tokenization Platform" />
          </router-link>
        </div>
        <div class="nav-links">
            <ul>
                <li><a href="#home">{{ $t('nav.hero') }}</a></li>
                <li><a href="#advantages">{{ $t('nav.advantages') }}</a></li>
                <li><a href="#offerings">{{ $t('nav.offerings') }}</a></li>
                <li><a href="#how-it-works">{{ $t('nav.howItWorks') }}</a></li>
                <li><a href="#get-started">{{ $t('nav.getStarted') }}</a></li>
                <li><a href="#contact">{{ $t('nav.contactUs') }}</a></li>
            </ul>
        </div>
        <div class="language-selector">
            <select v-model="selectedLanguage" @change="handleLanguageChange">
                <option value="en">🇬🇧 English</option>
                <option value="uk">🇺🇦 Українська</option>
            </select>
        </div>
        <button class="launch-button" @click="launchDapp">
            {{ $t('nav.launchDapp') }}
        </button>
      </nav>
    </header>
  </template>
  

  
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'HeaderSection',
    emits: ['changeLanguage'], // Объявляем событие
    setup(props, { emit }) {
      const selectedLanguage = ref('en'); // Установлено начальное значение
  
      const handleLanguageChange = (event) => {
        const value = event.target.value || selectedLanguage.value; // Берем значение из event или из v-model
        console.log(value);
        emit('changeLanguage', value); // Передаем язык родительскому компоненту
      };
  
      const launchDapp = () => {
        window.open('https://dapp.tryzubtokenization.com', '_blank');
      };
  
      return {
        selectedLanguage,
        handleLanguageChange,
        launchDapp,
      };
    },
  };
  </script>

  
  
  <style scoped>
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Полная ширина окна */
    height: 10vh; /* Высота 10% от высоты вьюпорта */
    background: rgba(32, 32, 64, 0.9); /* Полупрозрачный фон */
    color: #ffffff;
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.2); /* Легкая тень */
    z-index: 1000; /* Поверх всех секций */
  }
  
  .logo {
    position: absolute;
    top: 50%;
    left: 2vw; /* Расположение логотипа 2% от левого края */
    transform: translateY(-50%); /* Центрируем логотип по вертикали */
  }
  
  .img {
    /* Логотип занимает 4% ширины вьюпорта */
    height: 3vw; /* Сохраняем пропорции */
  }
  
  .nav-links {
    position: absolute;
    top: 50%;
    left: 6vw; /* Расположение ссылок на 20% от левого края */
    width: 70vw; /* Ширина блока ссылок 60% ширины вьюпорта */
    transform: translateY(-40%); /* Центрируем по вертикали */
    text-align: center;
  }
  
  .nav-links ul {
    display: inline-block; /* Ссылки идут в ряд */
    list-style: none; /* Убираем маркеры списка */
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    display: inline-block;
    margin: 0 0.5vw; /* Расстояние между ссылками */
  }
  
  .nav-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2vw; /* Размер шрифта 1.2% ширины вьюпорта */
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5vh 0.5vw; /* Вертикальный отступ 0.5% высоты, горизонтальный 1% ширины */
    transition: color 0.3s ease, background 0.3s ease;
  }
  
  .nav-links a:hover {
    color: #ffdd44;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0.5vw; /* Скругление углов */
  }
  
  .language-selector {
    position: absolute;
    top: 50%;
    right: 16vw; /* Расположено на 12% от правого края */
    transform: translateY(-50%);
  }
  
  .language-selector select {
    background: rgba(32, 32, 64, 0.9);
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 1vh; /* Скругление зависит от высоты */
    padding: 0.5vh 1vw; /* Вертикальный отступ 0.5%, горизонтальный 1% */
    font-size: 1.2vw;
    cursor: pointer;
  }
  
  .language-selector select:hover {
    border-color: #ffdd44;
    box-shadow: 0 0 0.5vh #ffdd44;
  }
  
  .launch-button {
    position: absolute;
    top: 50%;
    right: 2vw; /* Расположение кнопки 2% от правого края */
    transform: translateY(-50%);
    background: #ffdd44;
    color: #333333;
    border: none;
    padding: 0.8vh 2vw; /* Отступы зависят от высоты и ширины вьюпорта */
    font-size: 1.2vw;
    font-weight: bold;
    border-radius: 1vh; /* Скругление углов */
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .launch-button:hover {
    background: #ffd700;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .navbar {
      height: 15vh; /* Увеличенная высота для мобильных устройств */
    }
  
    .logo {
      left: 5vw; /* Логотип чуть дальше от края */
    }
  
    .img {
      width: 6vw; /* Увеличенный логотип */
    }
  
    .nav-links {
      left: 10vw; /* Ссылки ближе к левому краю */
      width: 80vw; /* Ширина блока ссылок 80% */
    }
  
    .nav-links a {
      font-size: 1.5vw; /* Крупнее текст ссылок */
    }
  
    .language-selector {
      right: 8vw;
    }
  
    .launch-button {
      right: 4vw;
    }
  }
  </style>